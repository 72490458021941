// Utils
import getConferencesByRY from './getConferencesByRY';
import { getIsManagerByYear } from './getIsManagerByYear';

import { getRotaryYear } from '@utils/datetime';

// Types
import {
  GetAccessLevelsQuery,
  GetConferencesResults,
} from '@domui-use-cases/districts';

export const isCreateConferenceBtn = (
  conferences: GetConferencesResults[],
  districtAccessLvl: GetAccessLevelsQuery | undefined
): boolean => {
  const currentRY = Number(getRotaryYear());
  const nextRY = currentRY + 1;

  const isAddConferenceForCurrentRY =
    getIsManagerByYear(districtAccessLvl, currentRY) &&
    !getConferencesByRY(conferences, currentRY).length;

  const isAddConferenceForNextRY =
    getIsManagerByYear(districtAccessLvl, nextRY) &&
    !getConferencesByRY(conferences, nextRY).length;

  return isAddConferenceForCurrentRY || isAddConferenceForNextRY;
};
