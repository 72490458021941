import React, { useEffect } from 'react';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

import ActionButtons from '../components/ActionButtons';
import ActionButtonsMobile from '../components/ActionButtonsMobile';
import DistrictConferenceSection from '../components/DistrictConferenceSection';

import {
  // ACCESS_LEVEL_TARGETS,
  mapConferencesAccessLevel,
} from '@domui-domain/districts';
// import { useFetchConferences } from '@repositories/districts/hooks';
// import { getRotaryYear } from '@utils/datetime';
import { GetAccessLevelsQuery } from '@domui-domain/districts/mappers/types';
import { RecordList } from '@domui-domain/type';
import { useFetchAllConferencesByDistrictPrimary } from '@domui-hooks/conference/useAllConferencesByDistrictPrimary';
import { useFetchConferenceList } from '@domui-hooks/iam/useFetchConferenceList';
// import { GetConferencesResults } from '@domui-use-cases/districts';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import {
  // getIsManagerByYear,
  getSplitConferences,
  isCreateConferenceBtn,
} from '@domui-use-cases/districts/conferences/index';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
// import { useFetchAccessLevels } from '@hooks/useFetchAccessLevels';

interface Props {
  districtId: string;
  hasPermissionToConference: boolean;
  accessLevelData: GetAccessLevelsQuery;
}

const DistrictConferences: React.FC<Props> = ({
  districtId,
  hasPermissionToConference,
  accessLevelData: districtAccessLevelByYears,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;
  const {
    data: conferenceData,
    loading: conferenceLoading,
    fetchAllConferencesByDistrictPrimary,
  } = useFetchAllConferencesByDistrictPrimary();

  useEffect(() => {
    if (districtId) {
      fetchAllConferencesByDistrictPrimary(districtId);
    }
  }, [districtId]);

  const {
    data: accessLevelDataForConferences,
    loading: accessLvlLoading,
    fetchConferencePermission,
  } = useFetchConferenceList();

  const payload = {
    data: [
      {
        target: `/conferences?associatedDistrictId=${districtId}`,
        arguments: {
          type: 'DistrictConferences',
        },
      },
    ],
  };

  useEffect(() => {
    fetchConferencePermission(requestorId, payload);
  }, [requestorId]);

  // const currentRY = getRotaryYear();
  // const RY = Number(currentRY);
  // const nextRY = RY + 1;

  // const isFutureDG = getIsManagerByYear(districtAccessLevelByYears, nextRY);

  if (!hasPermissionToConference) {
    return <NotFound default />;
  }

  if (conferenceLoading || accessLvlLoading || !conferenceData) {
    return <Loading />;
  }

  const conferences: RecordList = conferenceData || [];

  const { pastConferences, upcomingConferences } = getSplitConferences(
    conferences
  );

  const conferenceAccessLvls = mapConferencesAccessLevel(
    accessLevelDataForConferences
  );

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-conferences',
          '{{prefix}} District conferences {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="conference-page">
        <DistrictConferenceSection
          districtId={districtId}
          conferences={upcomingConferences}
          title={t('conferences.upcoming-title', 'Upcoming Conferences')}
          isUpcomingConference
          conferenceAccessLvls={conferenceAccessLvls}
          // Create Conference button is displayed for current or future DG if there is no conferences in their managment year
          isAddConferenceShown={isCreateConferenceBtn(
            conferences,
            districtAccessLevelByYears
          )}
          renderActionsButton={ActionButtons}
          renderActionsButtonMobile={ActionButtonsMobile}
        />
        <DistrictConferenceSection
          districtId={districtId}
          conferenceAccessLvls={conferenceAccessLvls}
          conferences={pastConferences}
          title={t('conferences.past-title', 'Past Conferences')}
          renderActionsButton={ActionButtons}
          renderActionsButtonMobile={ActionButtonsMobile}
        />
      </div>
    </>
  );
};

export default DistrictConferences;
