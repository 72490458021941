// Libs
import { groupBy, last } from 'lodash';
import moment, { unitOfTime } from 'moment';

// Utils
// import { filterDOsByDate } from './';

// Types
import { OfficerAssignedToRole } from '@domain/districts';

import { getYear } from '@utils/datetime';

// import { DistrictOfficer as DO } from '@typings/graphql';

export type Maybe<T> = T | null;

export const isDateBetween = (
  dateToCompare: string,
  between: {
    start: Maybe<string> | undefined;
    end: Maybe<string> | undefined;
  },
  granularity: unitOfTime.StartOf = 'y'
) =>
  moment(dateToCompare).isBetween(
    between.start,
    between.end,
    granularity,
    '[]'
  );

const isSameInYear = (date: string, datePart: string, endDate = false) => {
  const convertedDate = moment(date);
  const dateToCompare = `${convertedDate.year()}${datePart}`;
  if (endDate) {
    return convertedDate.isSameOrAfter(dateToCompare);
  }
  return convertedDate.isSameOrBefore(dateToCompare);
};

const isSelectedYearBetweenDates = (
  selectedYear: number,
  {
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }
) => {
  const startYear = `${selectedYear - 1}-07-01`;
  const endYear = `${selectedYear}-06-30`;
  const betweenDates = {
    start: startYear,
    end: endYear,
  };

  const isStartDateInSelectedYear = isDateBetween(startDate, betweenDates, 'd');
  const isEndDateInSelectedYear = isDateBetween(endDate, betweenDates, 'd');

  const isSelectedYearInLeadershipTerm = isDateBetween(`${selectedYear}`, {
    start: startDate,
    end: endDate,
  });

  // check if endDate & startDate in the year ->
  // so, for startDate - it will take startDate.year for making `startDate.year-07-01`
  // for endDate will be the same - `endDate.year-06-30`
  const isStartDateInSameYear = isSameInYear(startDate, '-07-01');
  const isEndDateInSameYear = isSameInYear(endDate, '-06-30', true);

  return (
    isStartDateInSelectedYear ||
    isEndDateInSelectedYear ||
    (isSelectedYearInLeadershipTerm &&
      (isStartDateInSameYear || isEndDateInSameYear))
  );
};

const getFormattedLeadershipItem = (
  officers: OfficerAssignedToRole[],
  selectedYear: number
) => {
  const firstLeadershipItem = officers[0];
  const leadershipId =
    // get leadershipId per selected year =>
    // if we have 3 year term role, for each year leadershipId will be diff
    officers.find(item => moment(item.endDate).year() === selectedYear)?.id ||
    last(officers)!.id;

  return {
    ...firstLeadershipItem,
    // we need to have correct id of Leadership here
    // definition of correct id for 3 term role -
    // if role started in the previous year, then:
    // - for current year we should have leadership that matched with `current year` record
    // - for previous - `previous year` record ID
    // - for future - `future year` record ID
    // that is for case when we have 3 records for leadership instead of 1
    id: leadershipId,
    // as we have sorted items, we assume that
    // `firstItem` has correct startDate
    startDate: firstLeadershipItem.startDate,
    // we have every time some item
    endDate: last(officers)!.endDate,
  };
};

const getIndexOfPartialTerm = (officers: OfficerAssignedToRole[]) =>
  officers.findIndex(({ startDate, endDate }) => {
    const formattedStartDate = moment(startDate);
    const formattedEndDate = moment(endDate);

    return (
      !formattedStartDate.isSame(`${formattedStartDate.year()}-07-01`, 'd') ||
      !formattedEndDate.isSame(`${formattedEndDate.year()}-06-30`, 'd')
    );
  });

const filterLeadershipsByTermLength = (
  officers: OfficerAssignedToRole[],
  initialStartDate: string,
  termLength: number
) =>
  officers.filter(({ endDate }) => {
    const startYear = moment(initialStartDate).year();
    const ed = moment(endDate);
    return !(ed.year() - startYear > termLength);
  });

const getFilledLeaderships = (groupedOfficers: OfficerAssignedToRole[]) =>
  [...groupedOfficers]
    // there can be 1 item in the array with this flag
    // if we have empty full/part term
    .filter(grouped => grouped.id)
    // we need to sort records by date from lower to higher
    // so we can make final object with correct start & end dates
    .sort((a, b) => (a.startDate > b.startDate ? 1 : -1));

const getFilteredGroupedLeadership = (
  groupedIndividuals: { [key: string]: OfficerAssignedToRole[] },
  termLength: number,
  selectedYear: number
) =>
  ((Object.keys(groupedIndividuals)
    .map(key => {
      // filter role for getting correct period of dates
      const groupedOfficers = groupedIndividuals[key];
      const priorYearOfficer = groupedOfficers.find(el => {
        const endDateYear = getYear(el.endDate);
        const startDateYear = getYear(el.startDate);
        return (
          endDateYear - startDateYear === termLength &&
          selectedYear > startDateYear &&
          selectedYear <= endDateYear
        );
      });
      let officers = priorYearOfficer
        ? [priorYearOfficer]
        : filterLeadershipsByTermLength(
            groupedOfficers,
            groupedOfficers[0].startDate,
            termLength
          );
      // getting last index of partial-term
      const index = getIndexOfPartialTerm(officers);
      if (index !== -1 && index !== groupedOfficers.length - 1) {
        // if we have some values after partial term, we should get them
        const areOfficersForSelectedTerm = groupedOfficers.some(
          ({ endDate, startDate }, officerIndex) =>
            officerIndex > index &&
            (moment(endDate).year() === selectedYear ||
              isSelectedYearBetweenDates(selectedYear, {
                startDate,
                endDate,
              }))
        );
        // otherwise get only values that includes part-term
        officers = groupedOfficers.filter((_, _index) =>
          areOfficersForSelectedTerm ? _index > index : _index <= index
        );
      }
      const firstLeadershipItem = officers[0];
      if (officers.length > 1) {
        const item = getFormattedLeadershipItem(officers, selectedYear);
        return isSelectedYearBetweenDates(selectedYear, item) ? item : null;
      }
      return firstLeadershipItem &&
        isSelectedYearBetweenDates(selectedYear, firstLeadershipItem)
        ? firstLeadershipItem
        : null;
    })
    .filter(Boolean) || []) as OfficerAssignedToRole[]).sort((a, b) =>
    a.startDate > b.startDate ? -1 : 1
  );

export const getTermsByTermLength = (
  officersGroupedByRole: { [key: string]: OfficerAssignedToRole[] },
  termLength: number,
  roleName: string,
  selectedYear: number
  // isViewer?: boolean
) => {
  // if we have termLength > 1 -> we need to group records into one
  // if we have termLength > 1 AND only 1 record, -> we can return it without grouping
  const roleInfo = officersGroupedByRole[roleName] || [];
  if (termLength > 1 && roleInfo.length > 1) {
    const filteredOfficers = getFilledLeaderships(roleInfo);

    const groupedIndividuals = groupBy(filteredOfficers, 'individual.id');
    // if we have diff members assigned to the role (2/3/etc years)
    // we need to group them in full term
    return getFilteredGroupedLeadership(
      groupedIndividuals,
      termLength,
      selectedYear
    );
  }
  // if (isViewer && roleInfo.length > 0) {
  //   return filterDOsByDate(String(selectedYear), roleInfo as DO[]);
  // }
  return roleInfo.filter(item =>
    isSelectedYearBetweenDates(selectedYear, item)
  );
};
