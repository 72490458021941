import React, { useContext } from 'react';

// import SelectMemberForAGStep from '@presenters/web/pages/Districts/ManageAssistantGovernors/SelectMemberStep';
import SelectTermStep from '@domui-presenters/web/pages/Districts/ManageAssistantGovernors/SelectTermStep';

import AssignRoleStep from './AssignRoleStep';
// import ReplaceRole from './ReplaceRole';
// import SelectMemberStep from './SelectMemberStep';
// import UnnassignRole from './UnassignRole';

import {
  //  ASSISTANT_GOVERNOR,
  OperationType,
} from '@domui-domain/districts';

import {
  DistrictContext,
  //  filterDistrictLeadershipByRoles,
} from '@domui-use-cases/districts';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const Step1: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const [context] = useContext(DistrictContext);
  const {
    operationType,
    // selectedInfo
  } = context;

  // const { unassignFrom, role } = selectedInfo;

  switch (operationType) {
    case OperationType.ASSIGN_TO_ROLE:
      return (
        <AssignRoleStep districtId={districtId} riDistrictId={riDistrictId} />
      );
    // case OperationType.ASSIGN_TO_MEMBER:
    // case OperationType.REASSIGN_OFFICER:
    // case OperationType.REPLACE_OFFICER:
    //   return (
    //     <SelectMemberStep districtId={districtId} riDistrictId={riDistrictId} />
    //   );
    // case OperationType.UNASSIGN_OFFICER_FROM_ROLE:
    // case OperationType.UNASSIGN_ROLE_FROM_OFFICER:
    //   return (
    //     <UnnassignRole districtId={districtId} riDistrictId={riDistrictId} />
    //   );
    // case OperationType.REPLACE_ROLE: {
    //   const filteredLeadershipRoles = filterDistrictLeadershipByRoles(
    //     unassignFrom?.thisDistrictLeadership || []
    //   );
    //   if (filteredLeadershipRoles.length === 1) {
    //     if (
    //       filteredLeadershipRoles[0].role === ASSISTANT_GOVERNOR ||
    //       role?.name === ASSISTANT_GOVERNOR
    //     ) {
    //       return (
    //         <SelectMemberForAGStep
    //           districtId={districtId}
    //           riDistrictId={riDistrictId}
    //           stepCount={3}
    //           isReplace
    //         />
    //       );
    //     }
    //     return (
    //       <SelectMemberStep
    //         districtId={districtId}
    //         riDistrictId={riDistrictId}
    //       />
    //     );
    //   }
    //   return (
    //     <ReplaceRole districtId={districtId} riDistrictId={riDistrictId} />
    //   );
    // }
    case OperationType.ASSIGN_TO_AG:
      return <SelectTermStep riDistrictId={riDistrictId} />;
    default:
      return null;
  }
};

export default Step1;
