// Libs
import moment, { Moment } from 'moment';

// Hooks
import {
  // useAssignDistrictLeadership,
  useDeleteDistrictLeadership,
  useUpdateDistrictLeadership,
  useReplaceAgClubAffiliations,
} from '@repositories/districts';

import { useAssignRoleToMember } from 'src/domui/hooks/useAssignRoleToMember';

import { getEndRYYear } from '@domui-utils/datetime';

// Types
import { Maybe } from 'graphql/jsutils/Maybe';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

export const useManageDistrictLeadership = ({
  districtId,
}: // riDistrictId,
Props) => {
  const {
    // loading: assignRoleToMemberLoading,
    assignRoleToMember,
  } = useAssignRoleToMember();
  const [
    updateDistrictLeadership,
    { loading: updateDistrictLeadershipLoading },
  ] = useUpdateDistrictLeadership();
  const [
    deleteDistrictLeadership,
    { loading: deleteDistrictLeadershipLoading },
  ] = useDeleteDistrictLeadership();

  const [
    replaceAgClubAffiliations,
    { loading: replaceAgClubAffiliationsLoading },
  ] = useReplaceAgClubAffiliations();

  const isLoading =
    // assignRoleToMemberLoading ||
    updateDistrictLeadershipLoading ||
    deleteDistrictLeadershipLoading ||
    replaceAgClubAffiliationsLoading;

  const dateFormat = 'YYYY-MM-DD';

  const updateDLForCurrentRY = async (
    leadershipId: string,
    dateToRemove: Date | Moment,
    isExtraData?: Maybe<boolean>
  ) =>
    updateDistrictLeadership({
      variables: {
        districtId,
        leadershipId,
        terminationDate: moment(dateToRemove).format(dateFormat),
        isExtraData,
      },
    });

  const updateDLForFutureRY = async (leadershipId: string) =>
    deleteDistrictLeadership({
      variables: {
        districtId,
        leadershipId,
      },
    });

  const replaceClubAffiliationsForFutureRY = async (
    individualId: string,
    newIndividualId: string,
    startDate: string
  ) =>
    replaceAgClubAffiliations({
      variables: {
        districtId,
        individualId,
        newIndividualId,
        startDate,
      },
    });

  const assignDL = async (
    individualId: string,
    roleId: string,
    assignDate: Date | string | Moment
  ) => {
    const formattedDate = moment(assignDate).format(dateFormat);
    const endDate = `${getEndRYYear(formattedDate)}-06-30`;

    return await assignRoleToMember({
      individualPk: individualId,
      organizationPk: districtId,
      rolePk: roleId,
      startDate: formattedDate,
      endDate,
    });
  };

  return {
    assignDL,
    updateDLForFutureRY,
    updateDLForCurrentRY,
    replaceClubAffiliationsForFutureRY,
    isLoading,
  };
};
