/* eslint-disable react/jsx-no-useless-fragment */
// Libs
import React from 'react';

import classNames from 'classnames';

// Components
import Link from '@components/Link';

// Types
import { RenderActionsButton } from '@domain/districts';

// Utils
import {
  getActionsConfig,
  getConferenceViewPage,
  getEditConferencePath,
} from '@use-cases/districts';

import { isEditLevel } from '@utils/access-helpers';

// Hooks
import { useTranslation } from '@external/react-i18next';

const ActionButtons: RenderActionsButton = (
  districtId,
  conference,
  onDelete,
  conferenceAccessLvls,
  conferenceFeedbackAccessLvls
) => {
  const { t } = useTranslation();

  const {
    id: conferenceId,
    relatedRotaryYear,
    createdByRiDistrictId,
  } = conference;

  const {
    isMyDistrict,
    canEditConference,
    canRemoveConference,
  } = getActionsConfig(conference, districtId, conferenceAccessLvls);

  const renderFeedbackButton = () => {
    if (!isMyDistrict) {
      return (
        <span className="block text-center italic pr-2">
          {t(
            'conference.created-by',
            'Created by {{ createdByRiDistrictId }}',
            { createdByRiDistrictId: createdByRiDistrictId }
          )}
        </span>
      );
    }

    if (isEditLevel(conferenceFeedbackAccessLvls?.[conference?.id])) {
      return (
        <div className="mr-4 w-full text-right">
          <Link
            to={`/district/${districtId}/conferences/${conferenceId}/${relatedRotaryYear}/feedback/DG`}
          >
            {t('conference.feedback-link', 'Feedback')}
          </Link>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {canEditConference ? (
        <>
          <Link
            className="mr-6"
            to={getEditConferencePath(districtId, conferenceId)}
          >
            {t('conference.edit-link', 'Edit')}
          </Link>
          <button
            disabled={!canRemoveConference}
            className={classNames(
              'font-normal disabled:cursor-auto',
              canRemoveConference ? 'link-styles' : 'link-styles-off'
            )}
            onClick={() => onDelete(conference)}
          >
            {t('conference.remove-link', 'Remove')}
          </button>
        </>
      ) : (
        <>
          {renderFeedbackButton()}
          <Link to={getConferenceViewPage(districtId, conferenceId)}>
            {t('conference.view-link', 'View')}
          </Link>
        </>
      )}
    </>
  );
};

export default ActionButtons;
