import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
// import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';
import Confirmation from '@domui-presenters/web/pages/Districts/CommitteeAppointments/Confirmation';

import { getActiveMembership, isRoleAvailable } from './utils';

import {
  CommitteeAppointment,
  OperationType,
  PartTimeStartDate,
  Step,
  TermToAssign,
} from '@domui-domain/districts';
import { FORM_STATUSES } from '@domain/storage';

import {
  buildRoleName,
  defaultContextState,
  DistrictContext,
  getBackUrl,
  getCurrentTermByTermYears,
  getFutureTermByTermYears,
  getStartDateToAssign,
  getUnassignedTermStartDate,
  isCurrentTerm,
  use3yrRoleConfirmation,
  // useModal,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { useModal } from '@use-cases/districts';
import { useNotifications } from '@use-cases/notifications';

import { useLocalStorage } from '@domui-repositories/storage/hooks';

import { pushLocalisedUrl } from '@utils/browserHistory';
import { getRotaryYear, getEndRYYear } from '@domui-utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useLogger } from '@hooks/logger';

import { LogLevel, Maybe } from '@typings/graphql';
import { useAssignRoleToMember } from '@domui-hooks/useAssignClubOfficerRole';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  // isLoading: boolean;
};

const AssignRoleStepConfirm: React.FC<Props> = ({
  districtId,
  // riDistrictId,
  // isLoading,
}) => {
  const { t } = useTranslation();

  const { addError, addSuccess } = useNotifications();
  const { addLog } = useLogger();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBack, setIsBack] = useState(false);
  const [context, setContext] = useContext(DistrictContext);

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    setIsBack,
    isStepMax: true,
  });

  const { updateStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    selectedInfo: { assignee, role, term },
    operationType,
    committeeManager: { isManager },
    managedRYs: { current, future },
  } = context;

  // const [
  //   assignDistrictLeadership,
  //   { loading: assignDistrictLeadershipLoading },
  // ] = useAssignDistrictLeadership();

  const {
    // loading: assignRoleToMemberLoading,
    assignRoleToMember,
  } = useAssignRoleToMember();

  const {
    isCommunicationConfirmed,
    renderConfirmation,
  } = use3yrRoleConfirmation();

  const backUrl = getBackUrl(operationType, districtId);
  const currentRY = getRotaryYear();
  const yearNow = Number(currentRY);
  const isReassign = operationType === OperationType.REASSIGN_TO_ROLE;

  // we need to get Member type first
  // If we have two membership Member + Rotaractor
  // we still need to take Member
  // In case when we have only Rotaractor -> first `get` will return nothing
  // then we will get Rotaractor admission & termination Date
  const activeMembership = assignee && getActiveMembership(assignee);

  const getNowTerms = (selectedTerm: Maybe<TermToAssign>) => {
    const { nowTerm, afterNowTerm } = isManager;
    let termForNowYear = null;

    if (nowTerm && role && isRoleAvailable(role, current)) {
      const termForNow = getCurrentTermByTermYears(
        yearNow,
        role?.termYears || 0
      );

      if (
        moment(termForNow?.startDate).isSameOrAfter(
          selectedTerm?.startDate,
          'year'
        )
      ) {
        termForNowYear = termForNow;
      }
    }

    const termForElect =
      afterNowTerm && role && isRoleAvailable(role, future)
        ? getFutureTermByTermYears(yearNow, role?.termYears || 0)
        : null;

    return { termForNowYear, termForElect };
  };

  const { termForNowYear, termForElect } = getNowTerms(term);
  const [inputTermToReassign, setTermToReassign] = useState<
    Maybe<TermToAssign>
  >(null);

  const termToReassign = inputTermToReassign || termForNowYear || termForElect;
  // Reset appropriate props in context after
  // successful query or cancel on confirmation page
  // isElect, isManager, termsToAssign etc. should not be refreshed
  const resetContextValues = () => {
    const newValues = {
      ...context,
      operationType: null,
      step: 1 as Step,
      managedRYs: { ...defaultContextState.managedRYs },
      selectedInfo: {
        ...context.selectedInfo,
        role: defaultContextState.selectedInfo.role,
        term: isReassign ? context.selectedInfo.term : context.termsToAssign[0],
        assignee: defaultContextState.selectedInfo.assignee,
      },
    };
    updateStorage({ ...newValues, status: FORM_STATUSES.FINISHED });
  };

  const minDate = moment(`${yearNow - 1}-07-01`).toDate();

  const [partTimeStartDate, setPartTimeStartDate] = useState<PartTimeStartDate>(
    {
      value: null,
      minDate,
    }
  );

  useEffect(() => {
    if (
      !partTimeStartDate.value &&
      (isCurrentTerm(term) || isCurrentTerm(termToReassign))
    ) {
      const startDateRY = `${yearNow - 1}-07-01`;
      const admissionDate = moment(
        activeMembership?.admissionDate || startDateRY
      );

      const today = moment();
      const unassignedTermStartDate =
        role?.terms?.find(term => term.isAssignable)?.startDate ||
        getUnassignedTermStartDate(role?.terms || []);

      const lastAvailableTermStartDate = moment(
        unassignedTermStartDate || startDateRY
      );

      const availableTermOrToday =
        lastAvailableTermStartDate.isSameOrBefore(today, 'd') ||
        !lastAvailableTermStartDate.isValid()
          ? today
          : lastAvailableTermStartDate;

      // if admission date is in current year
      // then make calculations including admissionDate
      if (
        admissionDate.isValid() &&
        (admissionDate.isSameOrBefore(availableTermOrToday, 'd') ||
          admissionDate.isSameOrAfter(availableTermOrToday, 'd'))
      ) {
        const formattedAdmissionToDate = admissionDate.toDate();
        // if admission date is after formattedDate = value should be admission
        if (admissionDate.isSameOrAfter(availableTermOrToday, 'd')) {
          setPartTimeStartDate({
            value: formattedAdmissionToDate,
            minDate: formattedAdmissionToDate,
          });
          return;
        }
        if (
          admissionDate.isSameOrBefore(availableTermOrToday, 'd') &&
          admissionDate.isSameOrAfter(
            isReassign ? termToReassign?.startDate : term?.startDate,
            'd'
          )
        ) {
          // if we have partial term & admission date is before
          // minDate = unassignedTermStartDate
          if (admissionDate.isSameOrBefore(unassignedTermStartDate, 'd')) {
            const convertedPartialTermDate = moment(
              unassignedTermStartDate
            ).toDate();
            setPartTimeStartDate({
              value: convertedPartialTermDate,
              minDate: convertedPartialTermDate,
            });
          } else {
            setPartTimeStartDate({
              value: formattedAdmissionToDate,
              minDate: formattedAdmissionToDate,
            });
          }
          return;
        }
      }

      setPartTimeStartDate({
        value: lastAvailableTermStartDate.toDate(),
        minDate: lastAvailableTermStartDate.toDate(),
      });
    }
  }, [term, role, termToReassign, activeMembership]);

  const createDate = (year: number) => new Date(`${year}-06-30`);

  const handleSelectDate = (date: Date) =>
    setPartTimeStartDate(prevState => ({
      ...prevState,
      value: date,
    }));

  const handleSubmit = async () => {
    try {
      if (assignee && role && term) {
        const startDateToAssign = getStartDateToAssign(
          term,
          termToReassign as TermToAssign,
          isReassign,
          partTimeStartDate
        );
        const endDate = `${getEndRYYear(startDateToAssign)}-06-30`;
        await assignRoleToMember({
          individualPk: assignee.id,
          organizationPk: districtId,
          rolePk: role.id,
          startDate: startDateToAssign,
          endDate,
        });
        addSuccess(
          t(
            'assign-officer.confirmation.success',
            'Success! {{officerFullName}} has been assigned the role of {{role}}.',
            {
              officerFullName: assignee.name,
              role: role.name,
            }
          )
        );
        resetContextValues();
        localizedNavigate(backUrl);
      } else {
        addLog({
          level: LogLevel.Error,
          message: `
            An error occurred. Please provide
            assigneeId: ${assignee?.id},
            selectedRole: ${role},
            selectedTerm: ${term}
          `,
        });
      }
    } catch (error) {
      addError(
        t(
          'assign-member.confirmation.error-request',
          'An error occurred assigning your role'
        )
      );
    } finally {
      setGlobal(false);
      pushLocalisedUrl(backUrl);
    }
  };

  const onCancelHandler = () => {
    resetContextValues();
    setGlobal(false);
    localizedNavigate(backUrl);
  };

  const backHandler = () => {
    if (operationType === OperationType.REASSIGN_TO_ROLE) {
      onCancelHandler();
    } else {
      window.localStorage.setItem('isNextStepVisited', 'true');
      setContext(prevState => ({
        ...prevState,
        step: 1,
      }));
      setGlobal(false);
      window.scrollTo(0, 0);
    }
  };

  const onChangeTerm = (selectedTerm: TermToAssign) => {
    if (isReassign) {
      setTermToReassign(selectedTerm);
    } else {
      setContext(prevState => ({
        ...prevState,
        selectedInfo: {
          ...prevState.selectedInfo,
          term: selectedTerm,
        },
      }));
    }
  };

  const modalOnCancelHandler = () => {
    setIsBack(false);
    show(true);
  };

  const modalBackHandler = () => {
    setIsBack(true);
    show(true);
  };

  const getDatePickerLabel = (
    termToReassign: Maybe<TermToAssign>,
    partTimeStartDate: PartTimeStartDate,
    isReassign: boolean
  ) => {
    if (
      (termToReassign?.endDate === String(yearNow) &&
        partTimeStartDate.value) ||
      !isReassign
    ) {
      return t('assign-from.startDate.label', 'Start Date');
    }
    return '';
  };

  const endOfYear = createDate(yearNow);
  const { terminationDate = '' } = activeMembership || {};
  const roleName = buildRoleName(t, role);

  const getMaxDate = () => {
    if (terminationDate) {
      if (moment(terminationDate).isAfter(endOfYear)) {
        return endOfYear;
      }
      return new Date(terminationDate);
    }
    return endOfYear;
  };

  // if (assignRoleToMemberLoading) {
  //   return <Loading />;
  // }

  return (
    <>
      <PageSteps
        className="p-0"
        backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
        backHandler={modalBackHandler}
        step={2}
        total={2}
      >
        <Confirmation
          roleName={roleName || null}
          term={isReassign ? termToReassign : term}
          termYears={role?.termYears || null}
          operationType={operationType}
          termForNowYear={termForNowYear}
          termForElect={termForElect}
          assigneeEmail={assignee?.email || null}
          assigneeName={assignee?.nameWithPrefixSuffix || null}
          title={t('confirm-role', 'Confirm the role of {{selectedRole}}', {
            selectedRole: role ? roleName : null,
          })}
          labels={{
            officerLabel: t('assign-from.officer.label', 'Officer'),
            termLabel: t('assign-from.term.label', 'Term'),
            roleLabel: t('assign-from.role.label', 'Role'),
            emailLabel: t('assign-from.email.label', 'Email'),
            datePickerLabel: getDatePickerLabel(
              termToReassign,
              partTimeStartDate,
              isReassign
            ),
          }}
          onChangeTerm={onChangeTerm}
          minDate={partTimeStartDate.minDate}
          maxDate={getMaxDate()}
          selectedDateValue={partTimeStartDate.value || undefined}
          handleSelectDate={
            (partTimeStartDate.value && handleSelectDate) || undefined
          }
        />
        {renderConfirmation()}
        <ActionButtons
          submitBtnLabel={t('action-button.finish', 'Finish')}
          cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
          onCancel={modalOnCancelHandler}
          isSubmitDisabled={role?.termYears === 3 && !isCommunicationConfirmed}
          onSubmit={handleSubmit}
        />
      </PageSteps>
      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={handleSubmit}
        onContinue={isBack ? backHandler : onCancelHandler}
      />
    </>
  );
};

export default AssignRoleStepConfirm;
