import { pipe } from 'fp-ts/lib/pipeable';
import * as O from 'fp-ts/Option';
import * as R from 'ramda';

import {
  CLUB_SEARCH_FILTERS,
  ClubSearchFormValues,
  MEMBER_SEARCH_FILTERS,
  MemberSearchFormValues,
  SITE_SEARCH_FILTERS,
  SiteSearchFormValues,
} from '@domain/clubs/types';
import { MemberURLParams } from '@domui-domain/districts';
import { DISTANCE_KM, DISTANCE_MI, DISTANCE_OPTIONS } from '@domain/search';

import { localizedNavigate } from '@utils/localized-navigate';
import { getQueryParams } from '@utils/query-params';

import {
  ClubTypeFilterValue,
  MeetingType,
  OrganizationBase,
  WeekdayFull,
} from '@typings/operations';

export const buildCommitteeAppointmentsUrl = (districtId: string) =>
  `/domui/district/${districtId}/members/manage-committee-appointments`;

export const buildAGsManagerUrl = (districtId: string) =>
  `/domui/district/${districtId}/members/manage-assistant-governors`;

export const buildAGsPageUrl = (districtId: string) =>
  `/domui/district/${districtId}/members/assistant-governors`;

export const buildOfficersPageUrl = (districtId: string) =>
  `/domui/district/${districtId}/members/officers`;

export const buildMembersPageUrl = (districtId: string) =>
  `/domui/district/${districtId}/members`;

export const buildAGsClubAssignmentsUrl = (districtId: string) =>
  pipe('/club-assignments', R.concat(buildAGsPageUrl(districtId)));

export const buildConferencesPageUrl = (districtId: string) =>
  `/domui/district/${districtId}/conferences`;

export const getIsAGPAge = <T extends { pathname?: string }>(location?: T) =>
  !!location?.pathname?.includes('assistant-governors');

export const getIsFeedbackPage = <T extends { pathname?: string }>(
  location?: T
) => !!location?.pathname?.includes('/feedback/');

export const getDefaultDestination = () =>
  O.fromNullable(getQueryParams()?.destination as string);

export const extractDestination = (destinationOption: O.Option<string>) =>
  pipe(
    destinationOption,
    O.getOrElse(() => 'details')
  );

export const mapDestinationToURL = (
  districtId: string,
  destination: string
) => {
  switch (destination) {
    case 'officers':
      return buildOfficersPageUrl(districtId);
    case 'assistant-governors':
      return buildAGsPageUrl(districtId);
    case 'conferences':
      return buildConferencesPageUrl(districtId);
    default:
      return `/district/${districtId}/details`;
  }
};

export const getDistrictDestinationURL = (
  districtId: string,
  destinationOption: O.Option<string>
) =>
  pipe(destinationOption, extractDestination, (destination: string) =>
    mapDestinationToURL(districtId, destination)
  );

export const goToDistrictDestination = (
  districtId: string,
  destinationOption: O.Option<string> = getDefaultDestination()
) =>
  localizedNavigate(getDistrictDestinationURL(districtId, destinationOption));

/** Used URLSearchParams Polyfill to cover IE8 and above:
 * https://www.npmjs.com/package/url-search-params-polyfill
 *
 * Note:
 * If no param provided it returns all URL params as an object
 * Decodes and stringifies under the hood
 * Ignores '?' in the beginning of the query url
 * */
export const getQueryParamsFromURL = (url?: string, param?: string) => {
  const getUrl = new URL(url || window.location.href);
  const searchParams = new URLSearchParams(getUrl.search);

  if (param) {
    return searchParams.get(param);
  }

  return Object.fromEntries(searchParams);
};

export const addQueryParamsToURL = (
  url: string,
  paramsObj:
    | MemberURLParams
    | ClubSearchFormValues
    | MemberSearchFormValues
    | SiteSearchFormValues = {}
) => {
  const result = Object.entries(paramsObj)
    .map(
      ([key, val]) =>
        `${key}=${encodeURIComponent(val as keyof typeof paramsObj)}`
    )
    .join('&');
  // remove trailing slash from URL
  const correctedUrl = url.substr(-1) === '/' ? url.slice(0, -1) : url;
  return `${correctedUrl}?${result}`;
};

export const getClubParamsFromURL = (): ClubSearchFormValues => {
  const getUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(getUrl.search);
  return Object.values(CLUB_SEARCH_FILTERS).reduce((acc, cur) => {
    const paramValue = searchParams.get(cur);
    if (paramValue) {
      const floatConversionRequired =
        cur === 'meetingLocationLatitude' ||
        cur === 'meetingLocationLongitude' ||
        cur === 'meetingLocationRange';
      return {
        ...acc,
        [cur]: floatConversionRequired ? parseFloat(paramValue) : paramValue,
      };
    }
    return { ...acc };
  }, {});
};

export const getMemberParamsFromURL = (): MemberSearchFormValues => {
  const getUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(getUrl.search);
  return Object.values(MEMBER_SEARCH_FILTERS).reduce((acc, cur) => {
    const paramValue = searchParams.get(cur);

    if (paramValue) {
      return {
        ...acc,
        [cur]: cur === 'clubRoles' ? paramValue.split(',') : paramValue,
      };
    }
    return { ...acc };
  }, {});
};

export const getSiteParamsFromURL = (): SiteSearchFormValues => {
  const getUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(getUrl.search);
  return Object.values(SITE_SEARCH_FILTERS).reduce((acc, cur) => {
    const paramValue = searchParams.get(cur);
    if (paramValue) {
      return {
        ...acc,
        [cur]: paramValue,
      };
    }
    return { ...acc };
  }, {});
};

export const validateSiteParamsFromURL = (
  filtersFromURL: SiteSearchFormValues
): SiteSearchFormValues => {
  const formattedFilters = { ...filtersFromURL };

  if (!formattedFilters.keywords) {
    delete formattedFilters.keywords;
  }
  if (!formattedFilters.langCode) {
    delete formattedFilters.langCode;
  }
  if (!formattedFilters.contentType) {
    delete formattedFilters.contentType;
  }

  if (!formattedFilters.documentMimeType) {
    delete formattedFilters.documentMimeType;
  }
  if (!formattedFilters.topic) {
    delete formattedFilters.topic;
  }
  return formattedFilters;
};

export const validateMemberParamsFromURL = (
  filtersFromURL: MemberSearchFormValues
): MemberSearchFormValues => {
  const formattedFilters = { ...filtersFromURL };

  if (!formattedFilters.city) {
    delete formattedFilters.city;
  }
  if (!formattedFilters.club) {
    delete formattedFilters.club;
  }
  if (!formattedFilters.clubRole) {
    delete formattedFilters.clubRole;
  }

  if (!formattedFilters.clubRoles) {
    delete formattedFilters.clubRoles;
  }
  if (!formattedFilters.district) {
    delete formattedFilters.district;
  }
  if (!formattedFilters.districtRole) {
    delete formattedFilters.districtRole;
  }

  if (!formattedFilters.districtRoles) {
    delete formattedFilters.districtRoles;
  }
  if (!formattedFilters.email) {
    delete formattedFilters.email;
  }
  if (!formattedFilters.memberClubType) {
    delete formattedFilters.memberClubType;
  }
  if (!formattedFilters.memberTypes) {
    delete formattedFilters.memberTypes;
  }

  if (!formattedFilters.location) {
    delete formattedFilters.location;
  }
  if (!formattedFilters.country) {
    delete formattedFilters.country;
  }
  if (!formattedFilters.stateId) {
    delete formattedFilters.stateId;
  }

  return formattedFilters;
};

export const validateClubParamsFromURL = (
  filtersFromURL: ClubSearchFormValues,
  languages?: string[]
): ClubSearchFormValues => {
  const formattedFilters = { ...filtersFromURL };

  if (
    !Object.values(DISTANCE_OPTIONS.map(String)).includes(
      formattedFilters.distance as string
    )
  ) {
    delete formattedFilters.distance;
  }
  if (
    ![DISTANCE_MI, DISTANCE_KM].includes(
      formattedFilters.distanceUnits as string
    )
  ) {
    delete formattedFilters.distanceUnits;
  }
  if (
    !Object.values(ClubTypeFilterValue).includes(
      formattedFilters.clubType as ClubTypeFilterValue
    )
  ) {
    delete formattedFilters.clubType;
  }
  if (
    !formattedFilters.clubType ||
    ![OrganizationBase.Community, OrganizationBase.University].includes(
      formattedFilters.organizationBase as OrganizationBase
    )
  ) {
    delete formattedFilters.organizationBase;
  }
  if (
    !Object.values(WeekdayFull).includes(
      formattedFilters.weekday as WeekdayFull
    )
  ) {
    delete formattedFilters.weekday;
  }
  if (
    !Object.values(MeetingType).includes(
      formattedFilters.meetingType as MeetingType
    )
  ) {
    delete formattedFilters.meetingType;
  }

  if (
    languages &&
    !languages.includes(formattedFilters.meetingLang as string)
  ) {
    delete formattedFilters.meetingLang;
  }

  return formattedFilters;
};
