import { pipe } from 'fp-ts/lib/function';
import * as R from 'ramda';

import { isAG, ManagedRY } from '@domain/districts';

export const isMemberAGInFutureTerm = (
  memberId: string,
  futureTermAssignments: ManagedRY
) => {
  return pipe(
    futureTermAssignments,
    R.prop('officers'),
    R.any(
      officer =>
        officer.individual.id === memberId &&
        isAG(officer.roleId) &&
        pipe(
          futureTermAssignments,
          R.prop('roles'),
          R.any(role => role.id === officer.roleId)
        )
    )
  );
};
