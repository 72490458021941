// Libs
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { get, includes, replace } from 'lodash/fp';

import { DynamicData } from '@domui-domain/type';

import {
  AccessLevelsArguments,
  ConferencesAccessLvl,
  // GetAccessLevelsQuery,
  GetAccessLevelsResults,
} from './types';

const CONFERENCES_URL = '/conferences/';

const isConferenceTarget = (item: GetAccessLevelsResults) =>
  pipe(item, get('target'), includes(CONFERENCES_URL));

const getTargetTermYear = (args?: AccessLevelsArguments | null) =>
  pipe(
    O.fromNullable(args?.targetTermYear),
    O.getOrElse(() => '')
  );

export const mapConferencesAccessLevel = (
  accessLevelsData?: DynamicData | null | undefined
): ConferencesAccessLvl[] | null => {
  return pipe(
    O.fromNullable(accessLevelsData),
    O.map(item => item || []),
    O.map(arr =>
      pipe(
        arr,
        A.filter(isConferenceTarget),
        A.map(({ target, level, arguments: args }) => ({
          id: replace(CONFERENCES_URL, '', target),
          level,
          targetTermYear: getTargetTermYear(args),
        }))
      )
    ),
    O.toNullable
  );
};
