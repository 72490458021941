import { isDRFC } from '@domain/districts/constants';
import { Role, TermToAssign } from '@domain/districts/types';

import { getFormattedRYRange } from '@utils/datetime';

import { DynamicData } from '@domui-domain/type';
import { extractOfficersData } from '@domui-use-cases/districts';

import { DistrictOfficer } from '@typings/graphql';

export const get3yrTermRoles = (roles: Role[]) =>
  roles
    .filter(
      ({ detail, value }) =>
        detail.TermLength === 3 && detail.IsOfficer && !isDRFC(value)
    )
    .map(role => role.value);

export const getOfficerIdsByTermStartDate = (
  offisers: DistrictOfficer[],
  term: string
) =>
  offisers
    .filter(officer => term === officer?.startDate)
    .map(officer => officer.roleId);

export const getAssignedFuture3yrTermRoleIds = (
  future3yrTermOfficersData: DynamicData,
  term: TermToAssign | null
) =>
  getOfficerIdsByTermStartDate(
    extractOfficersData(future3yrTermOfficersData),
    getFormattedRYRange(Number(term?.endDate) + 1).startYear
  );

export const isRoleAssignedInFutureRY = (
  assignedFuture3yrTermRoleIds: string[],
  roleId: string
) => {
  return assignedFuture3yrTermRoleIds?.includes(roleId);
};
