import {
  AccessLevel,
  ConferencesAccessLvl,
} from '@domui-domain/districts/mappers/types';
import { GetConferencesResults } from '@domui-use-cases/districts';

export type Maybe<T> = T | null;

export type RenderActionsButton = (
  districtId: Maybe<string> | undefined,
  conference: GetConferencesResults,
  onDelete: (conference: GetConferencesResults) => void,
  conferenceAccessLvls?: ConferencesAccessLvl[] | null,
  conferenceFeedbackAccessLvls?: Record<string, AccessLevel>
) => null | JSX.Element;

export type ConferenceItemProps = {
  conference: GetConferencesResults;
  onDelete: (conference: GetConferencesResults) => void;
  canEditConference?: boolean;
  isMyDistrict?: boolean;
  alignActionsRight?: boolean;
  renderActionsButton: RenderActionsButton;
  renderActionsButtonMobile: RenderActionsButton;
  conferenceAccessLvls?: ConferencesAccessLvl[] | null;
  conferenceFeedbackAccessLvls?: Record<string, AccessLevel>;
};

export enum FEEDBACK_TYPES {
  DG = 'DG',
  PR = 'PR',
}

export enum ControlOperationType {
  TEXTBOX = 'TextBox',
  RADIO_BUTTONS = 'DropDown',
  CHECK_BOX = 'CheckBox',
}

export enum Answers {
  EXCELLENT = 'Excellent',
  GOOD = 'Good',
  FAIR = 'Fair',
  WEAK = 'Weak',

  YES = 'Yes',
  NO = 'No',

  TRUE = 'True',
  FALSE = 'False',

  EXCEEDED_EXPECTATIONS = 'Exceeded expectations',
  MET_EXPECTATIONS = 'Met expectations',
  DID_NOT_MET_EXPECTATIONS = 'Did not meet expectations',
}
