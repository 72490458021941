import React, { useContext } from 'react';

import ConfirmDetails from '@domui-presenters/web/pages/Districts/ManageAssistantGovernors/ConfirmDetails';
// import SelectMemberForAGStep from '@presenters/web/pages/Districts/ManageAssistantGovernors/SelectMemberStep';

import AssignRoleStepConfirm from './AssignRoleStepConfirm';
// import ReplaceRole from './ReplaceRole';
// import SelectMemberStep from './SelectMemberStep';
// import UnnassignRole from './UnassignRole';

import { OperationType } from '@domui-domain/districts';

import {
  DistrictContext,
  // filterDistrictLeadershipByRoles,
} from '@domui-use-cases/districts';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  // isLoading: boolean;
};

const Step2: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const [context] = useContext(DistrictContext);
  const { operationType } = context;

  // const { unassignFrom, assignee, role } = selectedInfo;

  switch (operationType) {
    case OperationType.ASSIGN_TO_ROLE:
    case OperationType.REASSIGN_TO_ROLE:
    case OperationType.ASSIGN_TO_MEMBER:
      return (
        <AssignRoleStepConfirm
          districtId={districtId}
          riDistrictId={riDistrictId}
          // isLoading={isLoading}
        />
      );
    // case OperationType.REPLACE_OFFICER:
    //   return (
    //     <ReplaceRole districtId={districtId} riDistrictId={riDistrictId} />
    //   );
    // case OperationType.UNASSIGN_ROLE_FROM_OFFICER:
    // case OperationType.UNASSIGN_OFFICER_FROM_ROLE:
    //   return (
    //     <UnnassignRole districtId={districtId} riDistrictId={riDistrictId} />
    //   );
    // case OperationType.REPLACE_ROLE: {
    //   const filteredLeadershipRoles = filterDistrictLeadershipByRoles(
    //     unassignFrom?.thisDistrictLeadership || []
    //   );
    //   if (
    //     (filteredLeadershipRoles.length === 1 &&
    //       filteredLeadershipRoles[0].role === ASSISTANT_GOVERNOR) ||
    //     role?.name === ASSISTANT_GOVERNOR
    //   ) {
    //     if (assignee) {
    //       return <ConfirmDetails />;
    //     }
    //     return (
    //       <SelectMemberForAGStep
    //         districtId={districtId}
    //         riDistrictId={riDistrictId}
    //         stepCount={filteredLeadershipRoles.length > 1 ? 4 : 3}
    //         isReplace
    //       />
    //     );
    //   }
    //   if (filteredLeadershipRoles.length === 1) {
    //     return (
    //       <ReplaceRole districtId={districtId} riDistrictId={riDistrictId} />
    //     );
    //   }
    //   return (
    //     <SelectMemberStep
    //       districtId={districtId}
    //       riDistrictId={riDistrictId}
    //       stepCount={3}
    //     />
    //   );
    // }
    case OperationType.ASSIGN_TO_AG:
      return <ConfirmDetails riDistrictId={riDistrictId} />;
    default:
      return null;
  }
};

export default Step2;
