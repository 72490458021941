// Libs
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';

import { AccessLevel, ConferencesAccessLvl } from '@domui-use-cases/districts';

export type Maybe<T> = T | null;

export const getIsConferenceManager = (
  relatedRotaryYear: string,
  conferenceId: Maybe<string> | undefined,
  data?: ConferencesAccessLvl[] | null
) => {
  return pipe(
    O.fromNullable(data),
    O.map(item => item || []),
    O.map(
      A.filter(
        ({ id, targetTermYear, level }) =>
          id === conferenceId &&
          targetTermYear === relatedRotaryYear &&
          level === AccessLevel.Manager
      )
    ),
    O.chain(A.head),
    O.map(Boolean),
    O.getOrElse(() => false)
  );
};
