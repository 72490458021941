/* eslint-disable react/jsx-no-useless-fragment */
// Libs
import React from 'react';

// Components
import ActionButtons from '@components/ActionButtons';

// Types
import { RenderActionsButton } from '@domain/districts';

import {
  getActionsConfig,
  getConferenceViewPage,
  getCreateOrUpdateFeedbackPage,
  getEditConferencePath,
} from '@use-cases/districts';

import { isEditLevel } from '@utils/access-helpers';
// Utils
import { localizedNavigate } from '@utils/localized-navigate';

// Hooks
import { useTranslation } from '@external/react-i18next';

const ActionButtonsMobile: RenderActionsButton = (
  districtId,
  conference,
  onDelete,
  conferenceAccessLvls,
  conferenceFeedbackAccessLvls
) => {
  const { t } = useTranslation();

  const {
    id: conferenceId,
    relatedRotaryYear,
    createdByRiDistrictId,
  } = conference;

  const {
    isMyDistrict,
    canEditConference,
    canRemoveConference,
  } = getActionsConfig(conference, districtId, conferenceAccessLvls);

  const renderCreateFeedbackButton = () => {
    if (isEditLevel(conferenceFeedbackAccessLvls?.[conference.id])) {
      return (
        <ActionButtons
          removeBtnLabel={t('conference.feedback-link', 'Feedback')}
          onRemove={() =>
            localizedNavigate(
              getCreateOrUpdateFeedbackPage(
                {
                  districtId,
                  id: conferenceId,
                  relatedRotaryYear,
                },
                'DG'
              )
            )
          }
          isColumn
          wrapperClassNames="w-full flex flex-col"
        />
      );
    }

    return null;
  };

  return (
    <>
      {canEditConference ? (
        <ActionButtons
          editBtnLabel={t('conference.edit-link', 'Edit')}
          removeBtnLabel={t('conference.remove-link', 'Remove')}
          onEdit={() =>
            localizedNavigate(getEditConferencePath(districtId, conferenceId))
          }
          onRemove={() => onDelete(conference)}
          isRemoveDisabled={!canRemoveConference}
          isColumn
          wrapperClassNames="w-full flex flex-col mt-4"
        />
      ) : (
        <>
          {!isMyDistrict && (
            <span className="italic">
              {t(
                'conference.created-by',
                'Created by {{ createdByRiDistrictId }}',
                { createdByRiDistrictId: createdByRiDistrictId }
              )}
            </span>
          )}
          <ActionButtons
            viewBtnLabel={t('conference.view-link', 'View')}
            onView={() =>
              localizedNavigate(getConferenceViewPage(districtId, conferenceId))
            }
            isColumn
            wrapperClassNames="w-full flex flex-col mt-4"
          />
          {renderCreateFeedbackButton()}
        </>
      )}
    </>
  );
};

export default ActionButtonsMobile;
