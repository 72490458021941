// Libs
import { Dispatch, SetStateAction, useEffect } from 'react';

import { isEmpty } from 'lodash';

// Utils
import {
  ASSISTANT_GOVERNOR,
  ASSISTANT_GOVERNOR_ROLE_ID,
  CommitteeAppointment,
  CommitteeAppointmentPartialData,
  CommitteeDistrictLeadership,
  DistrictMember,
  MemberURLParams,
  OperationType,
  RoleOption,
} from '@domui-domain/districts';

import {
  defaultContextState,
  getMemberFromURL,
} from '@domui-use-cases/districts';

// Types

export const useSetMemberDataFromURL = (
  contextData: CommitteeAppointment,
  setContextData: Dispatch<SetStateAction<CommitteeAppointment>>,
  paramsToExclude: Array<string>,
  URLParamsObject: MemberURLParams,
  isAGManager?: boolean
) => {
  const { operationType, managedRYs } = contextData;

  const {
    operationType: operationTypeParam,
    step: stepNumber,
    id = '',
    role,
    term,
    terms,
  } = URLParamsObject;

  const getMember: DistrictMember = getMemberFromURL(
    URLParamsObject,
    paramsToExclude
  );

  const getAssignee = () => {
    if (isAGManager) {
      return operationTypeParam === OperationType.REASSIGN_TO_AG
        ? getMember
        : null;
    }

    if (
      operationTypeParam === OperationType.ASSIGN_TO_AG ||
      operationTypeParam === OperationType.ASSIGN_TO_ROLE
    ) {
      return { ...getMember, id };
    }

    if (
      (operationTypeParam === OperationType.REASSIGN_TO_ROLE ||
        operationTypeParam === OperationType.UNASSIGN_OFFICER_FROM_ROLE) &&
      terms
    ) {
      const id = terms[0]?.individual?.id;

      return { ...getMember, id };
    }

    if (
      operationTypeParam === OperationType.REPLACE_ROLE ||
      operationTypeParam === OperationType.UNASSIGN_ROLE_FROM_OFFICER
    ) {
      return null;
    }

    return getMember;
  };

  const getUnassignFrom = () => {
    if (
      operationTypeParam === OperationType.ASSIGN_TO_AG ||
      operationTypeParam === OperationType.REASSIGN_TO_AG
    ) {
      return null;
    }

    if (operationTypeParam === OperationType.REPLACE_ROLE) {
      return { ...getMember, id };
    }

    return getMember;
  };

  const getRole = () => {
    const leaderships: CommitteeDistrictLeadership[] =
      getMember.thisDistrictLeadership;

    if (
      operationTypeParam === OperationType.REPLACE_ROLE &&
      leaderships.length === 1
    ) {
      return {
        id: leaderships[0].roleId,
        name: leaderships[0].role,
        termYears: leaderships[0].termYears,
      };
    }

    if (isAGManager || operationTypeParam === OperationType.ASSIGN_TO_AG) {
      return {
        id: ASSISTANT_GOVERNOR_ROLE_ID,
        name: ASSISTANT_GOVERNOR,
      };
    }

    if (operationTypeParam === OperationType.REPLACE_OFFICER) {
      const leadership: CommitteeDistrictLeadership =
        getMember.thisDistrictLeadership[0];

      return {
        id: leadership.roleId,
        name: leadership.role,
        termYears: Number(leadership.termYears),
      };
    }

    if (
      (operationTypeParam === OperationType.REASSIGN_TO_ROLE ||
        operationTypeParam === OperationType.UNASSIGN_OFFICER_FROM_ROLE) &&
      role
    ) {
      return {
        id,
        name: role,
        terms,
      };
    }

    return null;
  };

  // If URLParamsObject is not empty - link was opened in New Tab
  // Then we need to get data from ULR params and set into context
  useEffect(() => {
    if (!isEmpty(URLParamsObject)) {
      const updatedState: CommitteeAppointmentPartialData = {
        operationType: operationTypeParam as OperationType,
        step: stepNumber || 1,
        selectedInfo: {
          ...defaultContextState.selectedInfo,
          assignee: getAssignee(),
          role: getRole() as RoleOption | null,
          unassignFrom: getUnassignFrom(),
          term: term || null,
        },
      };

      setContextData(prevState => {
        return {
          ...prevState,
          step: updatedState.step || 1,
          operationType: updatedState.operationType,
          selectedInfo: {
            ...prevState.selectedInfo,
            ...updatedState.selectedInfo,
          },
        };
      });
    }
  }, [operationType, role, managedRYs, isAGManager]);
};
