import moment from 'moment';

import { ClubTypeFilterValue, DynamicData } from '@domui-domain/type';

type LeadershipRole = {
  id: string;
  value: string;
  detail: {
    organizationType: string | null;
    termLength: number;
    termOfAccessBefore: number;
    termOfAccessAfter: number;
    startDate: string;
    endDate: string | null;
    mustBeMembership: boolean | string[];
    isOfficer: boolean;
    restrictPerOrganizationFlag: boolean;
    // disUseFlag?: boolean;
    officerManagement: string;
    useFlag: string;
  };
};
export const mapLeadershipRoles = (
  {
    organizationType,
    validOn,
    officerManagement,
    rolesToInclude,
  }: {
    organizationType: string[];
    validOn: string | number;
    officerManagement: string;
    rolesToInclude?: string[];
  },
  results: DynamicData
) => {
  const validOnUTC = validOn && moment(validOn).utc();
  // If rolesToInclude => exclude all roles that have different officerManagement type except roles in the rolesToInclude array
  const isFilterByManagement = (management: string, roleName: string) => {
    const filterByManagement =
      officerManagement && management !== officerManagement;

    return rolesToInclude?.length
      ? filterByManagement && !rolesToInclude.includes(roleName)
      : filterByManagement;
  };
  const isFilterByOrganization = (organization: string | null) => {
    // console.log('organizationType', organizationType);
    // console.log('organization', organization);
    // console.log(
    //   " !organizationType?.includes(organization || '')",
    //   !organizationType?.includes(organization || '')
    // );
    return !organizationType?.includes(organization || '');
  };
  const isFilterByRotaryOrganization = (organization: string | null) => {
    // console.log('!organizationType?.length', !organizationType?.length);
    // console.log(
    //   ' organization !== ClubTypeFilterValue.RotaryClub',
    //   organization !== ClubTypeFilterValue.RotaryClub
    // );
    return (
      !organizationType?.length &&
      organization !== ClubTypeFilterValue.RotaryClub
    );
  };
  // console.log(
  //   'results filter',
  //   results.filter(({ detail, value }: DIS.LeadershipRole) => {
  //     console.log('detail', detail);
  //     if (
  //       isFilterByManagement(detail.OfficerManagement, value) ||
  //       isFilterByOrganization(detail.OrganizationType) ||
  //       isFilterByRotaryOrganization(detail.OrganizationType)
  //     ) {
  //       console.log('filter false');
  //       return false;
  //     }
  //     if (
  //       validOnUTC &&
  //       (validOnUTC.isBefore(detail.StartDate) ||
  //         (detail.EndDate && validOnUTC.isAfter(detail.EndDate)))
  //     ) {
  //       console.log('UTC false');
  //       return false;
  //     }
  //     if (!detail?.DISUseFlag) {
  //       console.log('DIS false');
  //       return false;
  //     }
  //     console.log('true');
  //     return true;
  //   })
  // );
  return (
    results
      ?.filter(({ detail, value }: LeadershipRole) => {
        if (
          isFilterByManagement(detail.officerManagement, value) ||
          isFilterByOrganization(detail.organizationType) ||
          isFilterByRotaryOrganization(detail.organizationType)
        ) {
          return false;
        }
        if (
          validOnUTC &&
          (validOnUTC.isBefore(detail?.startDate) ||
            (detail?.endDate && validOnUTC.isAfter(detail?.endDate)))
        ) {
          return false;
        }
        if (!detail?.useFlag) {
          return false;
        }

        return true;
      })
      .map(
        ({
          id,
          value,
          detail: {
            termLength,
            termOfAccessBefore,
            termOfAccessAfter,
            isOfficer,
            organizationType,
          },
        }: LeadershipRole) => ({
          id,
          name: value,
          termLength,
          termOfAccessBefore,
          termOfAccessAfter,
          isOfficer,
          type: organizationType,
        })
      )
      // sort alphabetically ascending by role name
      .sort(
        (
          { name: firstName }: { name: string },
          { name: secondName }: { name: string }
        ) => firstName.localeCompare(secondName)
      )
  );
};
