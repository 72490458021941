// Libs
import React, { useState } from 'react';

import Collapse from '@kunukn/react-collapse';
import { RouteComponentProps, useParams } from '@reach/router';
import classNames from 'classnames';

// Components
import Icon from '@components/Icon';
import ConferenceDurationSection from '@presenters/web/components/ConferenceDurationSection';

import ConferenceActions from './ConferenceActions';

// Utils
import { constructFullName } from '@utils/constructFullName';
import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import { ConferenceItemProps } from '@domui-domain/districts';
import { RecordList } from '@domui-domain/type';
import {
  getYearLabel,
  ParticipatingDistricts,
} from '@domui-use-cases/districts';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Types
import { MeetingType } from '@typings/graphql';

const MobileItem: React.FC<RouteComponentProps &
  ConferenceItemProps> = props => {
  const { conference, renderActionsButtonMobile } = props;
  const { t } = useTranslation();
  const { districtId } = useParams();

  const {
    relatedRotaryYear,
    startDate,
    endDate,
    conferenceAddress,
    presidentialRepresentative,
    participatingDistricts,
    venueType,
  }: RecordList = conference;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classNames(
        'tablet:hidden flex-grow p-5 pb-7 focus:shadow-none',
        {
          'bg-bright-blue-100': isExpanded,
        }
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center">
        <div className="flex-grow">
          <div>
            {relatedRotaryYear ? (
              <span>{getYearLabel(relatedRotaryYear)}.&nbsp;</span>
            ) : null}
            <span>
              <ConferenceDurationSection
                startDate={startDate}
                endDate={endDate}
                classess="inline"
              />
            </span>
          </div>
          <div>
            {(venueType === MeetingType.Online
              ? t('global.meetingtype.online', 'Online Only')
              : getPhysicalLocation(conferenceAddress)) || <>&#8212;</>}
          </div>
          <div>
            <span>
              {t('conferences.headers.president-rep', 'President Rep')}
              &#58;&nbsp;
            </span>
            {(presidentialRepresentative &&
              constructFullName(presidentialRepresentative)) || <>&#8212;</>}
          </div>
        </div>

        <div
          aria-hidden="true"
          className={classNames('arrow-down ml-2', {
            hidden: isExpanded,
          })}
        >
          <Icon name="arrows/down" size="17" color="black" />
        </div>

        {isExpanded && (
          <div aria-hidden="true" className="self-start ml-2">
            <Icon name="ui/close" size="17" color="black" />
          </div>
        )}
      </div>

      <Collapse isOpen={isExpanded} className="collapse-css-transition">
        <div className="flex w-full flex-col">
          <div>
            {participatingDistricts
              ?.map((item: ParticipatingDistricts) => item?.riDistrictId)
              .join(', ') || <>&#8212;</>}
          </div>
          <ConferenceActions
            {...props}
            districtId={districtId}
            renderButton={renderActionsButtonMobile}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default MobileItem;
