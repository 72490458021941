// Constants
import {
  ASSISTANT_GOVERNOR,
  DISTRICT_OFFICER_GOVERNOR_ROLES,
  isAG,
  isDRFC,
} from '@domui-domain/districts';

// Utils
import { canBeReplacedRemovedRole } from '@use-cases/districts';

// Types
import { Role } from '@typings/graphql';
import { DistrictLeadership } from '@typings/operations';

export const getAssignedInfoByRole = (
  leadershipInfo: DistrictLeadership[],
  DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[]
) => ({
  // can be removed/replaced:
  // - the individual does not hold the role of DRFC or District Manager
  // - and if we have endDate after today (at least 1 day diff)
  // - and if assigned role is DO role
  modifyAssignedRole: leadershipInfo.some(
    ({ roleId, role, endDate }) =>
      !(DISTRICT_OFFICER_GOVERNOR_ROLES.includes(role) || isDRFC(role)) &&
      canBeReplacedRemovedRole(endDate) &&
      DORoles.some(role => role.id === roleId)
  ),
  assignedToDO: leadershipInfo.some(leadership =>
    DORoles.filter(role => !isAG(role.id)).some(
      role => role.id === leadership.roleId
    )
  ),
  assignedToAG: leadershipInfo.some(
    leadership => leadership.role === ASSISTANT_GOVERNOR
  ),
});
