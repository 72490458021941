import { TFunction } from 'i18next';
import { v4 as uuid } from 'uuid';

import {
  addQueryParamsToURL,
  areSomeAssigmentAfter,
  buildCommitteeAppointmentsUrl,
  getAssignedInfoByRole,
  isMemberAGInFutureTerm,
} from '../helpers';

import {
  CommitteeDistrictLeadership,
  ContextMenuItem,
  DISTRICT_EXECUTIVE_SECRETARY,
  isDRFC,
  ManagedRY,
  MemberURLParams,
  OperationType,
  Step,
  TermToAssign,
} from '@domui-domain/districts';

import { isToday } from '@domui-utils/datetime';
import { flattenObject } from '@domui-utils/objectUtils';

import { DynamicData } from 'src/domui/domain/type';

import { MembershipType, Role } from '@typings/graphql';

export default (
  t: TFunction,
  isElect: boolean,
  isMemberOnly: boolean,
  isManagerFor2Terms: boolean,
  districtId: string,
  handleClickMenu: (
    committeeAppointmentsURL: string,
    operationType: OperationType
  ) => void,
  DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[],
  areRolesToAssignPresent: boolean,
  currentYearAssignment: ManagedRY,
  futureYearAssignment: ManagedRY,
  member: DynamicData,
  getUnassignFrom?: DynamicData,
  getAssignToAGStep?: Step,
  term?: TermToAssign,
  isAssignedInFutureRY?: boolean
) => (): ContextMenuItem[] => {
  const { membership, associatedClubsInfo, districtLeadership } = member || {};

  const isMember = membership.some(
    (membership: { type: MembershipType }) =>
      membership.type === MembershipType.Member
  );

  const isRotaractor = membership.some(
    (membership: { type: MembershipType }) =>
      membership.type === MembershipType.Rotaractor
  );
  const isDisabledReplace = districtLeadership.every(
    ({ startDate, role }: { startDate: string; role: string }) =>
      isToday(startDate) || isDRFC(role)
  );

  const isNonMemberOnly =
    (membership.length === 0 ||
      membership.every(
        ({ terminationDate }: { terminationDate: string }) =>
          terminationDate !== null
      )) &&
    districtLeadership.length === 1 &&
    districtLeadership[0].role === DISTRICT_EXECUTIVE_SECRETARY;

  const isHonoraryOnly =
    Boolean(associatedClubsInfo.length) &&
    associatedClubsInfo.every(({ clubId }: { clubId: string }) =>
      membership.some(
        (membership: { clubId: string; type: MembershipType }) =>
          membership.clubId === clubId &&
          (membership.type === MembershipType.Honorary ||
            membership.type === MembershipType.RotaractHonorary)
      )
    );

  // If user that is viewing member list is member only (not DG/DES, etc)
  // option list should be empty
  if (isMemberOnly) {
    return [];
  }

  const {
    assignedToAG,
    assignedToDO,
    modifyAssignedRole,
  } = getAssignedInfoByRole(districtLeadership || [], DORoles);

  const assignedToAGInFuture = isMemberAGInFutureTerm(
    member?.id,
    futureYearAssignment
  );

  const canBeAssignedToFutureAG =
    !assignedToAGInFuture && (isManagerFor2Terms || isElect);
  const canBeAssignedToCurrentAG =
    !assignedToAG && (isManagerFor2Terms || !isElect);
  const canBeAssignedToAG = canBeAssignedToCurrentAG || canBeAssignedToFutureAG;

  const committeeAppointmentsURL = `${buildCommitteeAppointmentsUrl(
    districtId
  )}/${uuid()}`;

  const isRoleAssignedAndIsNotElect =
    !isElect && (assignedToAG || assignedToDO);

  const isAccessibleReplaceRemove = () => {
    if (!modifyAssignedRole) {
      return false;
    }

    return isRoleAssignedAndIsNotElect;
  };

  const handleClick = (operationType: OperationType) => {
    handleClickMenu(committeeAppointmentsURL, operationType);
  };

  const menuItems = [
    {
      id: 1,
      label: t(
        'roster-result.context-menu.assign-district-officer-role',
        'Assign District Officer Role'
      ),
      path: addQueryParamsToURL(committeeAppointmentsURL, {
        ...flattenObject(member || {}),
        operationType: OperationType.ASSIGN_TO_ROLE,
      }),
      isAccessible:
        areRolesToAssignPresent &&
        !isNonMemberOnly &&
        (isMember || isRotaractor) &&
        !isHonoraryOnly,
      handleClick: () => handleClick(OperationType.ASSIGN_TO_ROLE),
    },
    {
      id: 2,
      label: t(
        'roster-result.context-menu.assign-assistant-governor-role',
        'Assign Assistant Governor Role'
      ),
      path: addQueryParamsToURL(committeeAppointmentsURL, {
        ...flattenObject(member || {}),
        ...flattenObject({ term }),
        step: getAssignToAGStep,
        operationType: OperationType.ASSIGN_TO_AG,
      }),
      isAccessible:
        canBeAssignedToAG && isMember && !isHonoraryOnly && !isNonMemberOnly,
      handleClick: () => handleClick(OperationType.ASSIGN_TO_AG),
    },
    {
      id: 3,
      label: t(
        'roster-result.context-menu.replace-with-someone-else',
        'Replace with someone Else'
      ),
      path: addQueryParamsToURL(committeeAppointmentsURL, {
        ...flattenObject(getUnassignFrom as MemberURLParams),
        operationType: OperationType.REPLACE_ROLE,
      }),
      isAccessible:
        isAccessibleReplaceRemove() ||
        (isAccessibleReplaceRemove() && isNonMemberOnly),
      isDisabled:
        isDisabledReplace ||
        districtLeadership.every(
          (
            leadership: Pick<CommitteeDistrictLeadership, 'endDate' | 'roleId'>
          ) => areSomeAssigmentAfter(currentYearAssignment.officers, leadership)
        ) ||
        isAssignedInFutureRY,
      handleClick: () => handleClick(OperationType.REPLACE_ROLE),
    },
    {
      id: 4,
      label: t(
        'roster-result.context-menu.remove-from-officer-role',
        'Remove from Officer Role'
      ),
      path: addQueryParamsToURL(committeeAppointmentsURL, {
        ...flattenObject(getUnassignFrom as MemberURLParams),
        operationType: OperationType.UNASSIGN_ROLE_FROM_OFFICER,
      }),
      isAccessible:
        isAccessibleReplaceRemove() ||
        (isAccessibleReplaceRemove() && isNonMemberOnly),
      handleClick: () => handleClick(OperationType.UNASSIGN_ROLE_FROM_OFFICER),
    },
  ];
  return menuItems.filter(item => item.isAccessible);
};
