import { DynamicData } from '@domui-domain/type';

// Types
import {
  AccessLevel,
  // GetAccessLevelsQuery
} from './types';

export const mapConferencesFeedbackAccessLevels = (
  accessLevelsData?: DynamicData
) =>
  accessLevelsData?.reduce(
    (
      acc: Record<string, AccessLevel>,
      { target, level }: Record<string, AccessLevel>
    ) => {
      const conferenceId = target.match(/conferences\/(.+)\/feedback/)?.[1];
      if (conferenceId) acc[conferenceId] = level;
      return acc;
    },
    {}
  );
